import { AppState } from '../context/AppContext';
import { UserAuth } from '../context/AuthContext';
import { AgGridReact } from 'ag-grid-react';
import { IoMdDownload, IoMdRefresh } from "react-icons/io";


import { getUserInfoFromUID, cleanUnpaidUsersFromEvent } from '../script/fbAPI';

import { useState, useEffect, useRef } from 'react';

import MemberAge from './MemberAge';

const EventResponsesModal = () => {
    const { event, setEvent, setSyncing } = AppState();

    // Conditional returns
    if (!event.data) return;
    if (!(event.type === 'responses')) return;

    const [filterText, setFilterText] = useState('');

    // Cancel button click
    const handleCancelClick = () => {
        setEvent({});
    };

    // AgGrid
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    let columns = [
        {
            field: 'name',
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        { field: 'paid', suppressMovable: true, width: 70 },
        { field: 'age', cellRenderer: MemberAge, width: 70 },
        { field: 'phone', suppressMovable: true },
        { field: 'emergency', suppressMovable: true },
        { field: 'canDrive', suppressMovable: true },
        { field: 'carQty', suppressMovable: true },
    ];

    let customQuestions = event.data.custom_questions;
    if (customQuestions) {
        for (var i = 0; i < customQuestions.length; i++) {
            columns.push({
                field: `question${i + 1}`,
                suppressMovable: true,
                resizable: true,
            });
        }
    }

    // AgGrid Search feature
    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.setQuickFilter(filterText);
        }
    }, [filterText]);

    const processResponses = async (responses) => {
        setSyncing(true);
        const userInfoPromises = [];

        for (const uid in responses) {
            // Push each promise returned by getUserInfoFromUID into the array
            userInfoPromises.push(getUserInfoFromUID(uid));
        }

        // Wait for all promises to resolve
        const allUserInfo = await Promise.all(userInfoPromises);

        const consolidatedResponses = [];
        for (var i = 0; i < allUserInfo.length; i++) {
            let response = responses[Object.keys(responses)[i]];
            let userInfo = allUserInfo[i];

            let data = {
                name: userInfo.name,
                paid: response.paid,
                dob: userInfo.dob,
                phone: userInfo.phone,
                emergency: userInfo.emergency,
                canDrive: response.can_drive ? 'Yes' : 'No',
                carQty: response.can_take_qty,
            };

            for (
                var j = 0;
                j < response.custom_question_responses.length;
                j++
            ) {
                data[`question${j + 1}`] =
                    response.custom_question_responses[j];
            }
            consolidatedResponses.push(data);
        }

        setSyncing(false); //
        return consolidatedResponses;
    };

    const handleGetResponses = async () => {
        let responses = event.data.responses;
        let processed = await processResponses(responses);
        setRowData(processed);
    };

    const handleRefreshClick = async () => {
        setSyncing(true);
        await cleanUnpaidUsersFromEvent(event.data);
        setSyncing(false);
    }

    const handleDownloadClick = () => {
        if (!rowData || !rowData.length) {
            return;
        }
    
        // Initialize a set to hold all unique headers across all rows
        const headersSet = new Set();
    
        // Add all object keys to the set to ensure all possible headers are captured
        rowData.forEach(row => {
            Object.keys(row).forEach(key => headersSet.add(key));
        });
    
        // Convert the set of headers into an array
        const headers = Array.from(headersSet);
    
        const csvRows = [];
    
        // Add the headers as the first row
        csvRows.push(headers.join(','));
    
        // Convert each JSON object to a CSV row
        rowData.forEach(row => {
            const values = headers.map(header => {
                // Handle values that contain commas or double-quotes
                const value = row[header] || ''; // Default to empty string if key doesn't exist in this row
                const escaped = ('' + value).replace(/"/g, '""');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
            // this
        });
    
        // Join all rows into a single string with newline characters
        const csvString = csvRows.join('\n');
    
        // Create a Blob with the CSV data
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    
        // Create a link and trigger the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        // Clean up the URL object
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        setColumnDefs(columns);
        handleGetResponses();
    }, []);

    return (
        <div className='modal-wrapper'>
            <div className='modal'>
                <div className='center-wrapper'>
                    <div className='event-edit-container max-width'>
                        {/* Event details */}
                        <div>
                            <div className='modal-header-space-between'>
                                <h3>{`${event.data.title} Responses`}</h3>
                                {/* Button to clean event */}
                                {/* <button onClick={() => handleRefreshClick()}>
                                    <IoMdRefresh />
                                </button> */}
                                <button onClick={() => handleDownloadClick()}>
                                    <IoMdDownload />
                                </button>
                            </div>
                            <p className='margin-bottom'>
                                All responses for this event.
                            </p>
                            {/* Search */}
                            <div className='text-input-wrapper short border margin-bottom'>
                                <input
                                    className='text-input'
                                    type='text'
                                    placeholder='Search...'
                                    value={filterText}
                                    onChange={(event) =>
                                        setFilterText(event.target.value)
                                    }
                                />
                            </div>
                            {/* AgGrid */}
                            <div
                                className='ag-theme-alpine'
                                style={{
                                    width: 'auto',
                                    height: 500,
                                }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                ></AgGridReact>
                            </div>
                        </div>
                        {/* Action buttons */}
                        <div className='confirm-button-grid'>
                            <button
                                className='secondary'
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventResponsesModal;
