import { useState, useEffect } from 'react';
import { updateParam } from '../script/fbAPI';
import { AppState } from '../context/AppContext';

const PermissionsMenu = (props) => {
    const { setSyncing } = AppState();
    const [permissions, setPermissions] = useState(1);

    const permissionOptions = [
        { label: 'Member', value: 1 },
        { label: 'Staff', value: 2 },
        { label: 'Exec', value: 3 },
    ];

    useEffect(() => {
        if (props.data.permissions) setPermissions(props.data.permissions);
    }, []);

    const handlePermissionsChange = async (newPermission) => {
        setSyncing(true);
        const user = props.data;
        await updateParam(user, 'permissions', newPermission);
        setSyncing(false);
        setPermissions(newPermission);
    };

    return (
        <div>
            <select
                value={permissions}
                onChange={(e) => handlePermissionsChange(Number(e.target.value))}
            >
                {permissionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PermissionsMenu;
