import { AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai';
import { FaExternalLinkAlt } from 'react-icons/fa';

import { createCheckoutSession } from '../script/fbAPI';

import { lnkref } from '../script/fbConfig';

import { UserAuth } from '../context/AuthContext';

import { useEffect, useState } from 'react';

import { AppState } from '../context/AppContext';

import { products } from '../script/localdata';

const IKONPanel = ({ inputs }) => {
    const { user } = UserAuth();
    const { verified, setSyncing } = AppState();

    const clearIKON = () => {
        clearIKONCodes();
    }

    const handleBuyMembership = async () => {
        setSyncing(true);
        const price_id = products['24-25-membership'].price_id;
        const result = await createCheckoutSession(user, price_id);
        if (result) setSyncing(false);
    };

    return (
        <div className='info'>
            {!verified && (
                <div className='locked-wrapper'>
                    <AiOutlineLock className='lock-icon' />
                    <div className='bars-container'>
                        <p className='mobiletext'>
                            Please fill out all profile information
                        </p>
                        <div className='bar-wrapper'>
                            <div
                                className={
                                    'bar' + (inputs.schedule ? ' complete' : '')
                                }
                            />
                            <p className='bar-subtext'>UCLA Schedule</p>
                        </div>
                        <div className='bar-wrapper'>
                            <div
                                className={
                                    'bar' + (inputs.num1 ? ' complete' : '')
                                }
                            />
                            <p className='bar-subtext'>Your phone</p>
                        </div>
                        <div className='bar-wrapper'>
                            <div
                                className={
                                    'bar' + (inputs.num2 ? ' complete' : '')
                                }
                            />
                            <p className='bar-subtext'>Emergency phone</p>
                        </div>
                        <div className='bar-wrapper'>
                            <div
                                className={
                                    'bar' + (inputs.date ? ' complete' : '')
                                }
                            />
                            <p className='bar-subtext'>Date of Birth</p>
                        </div>
                    </div>
                </div>
            )}
            {/* {verified && user.details && !user.details.ikon && !user.details.hasPaidDuesEarly && (
                <div className='locked-wrapper'>
                    <AiOutlineLock className='lock-icon' />
                    <div className='padded-notice'>
                        Only members for 2024/25 have access to the IKON discount code for this upcoming season. Your membership is currently only valid for the 2023/24 season. Paying now will extend your membership until the end of June 2025.
                    </div>
                    <button
                            className='primary'
                            onClick={handleBuyMembership}
                        >
                            Become a Member (24/25)
                    </button>
                </div>
            )} */}
            {verified && user.details && !user.details.ikon && (
                <div className='locked-wrapper'>
                    <AiOutlineUnlock className='lock-icon' />
                    <a href={lnkref} target='_blank'>
                        <button className='primary'>
                            IKON discount form
                            <FaExternalLinkAlt className='inline-icon' />
                        </button>
                    </a>
                </div>
            )}
            {verified && user.details && user.details.ikon && (
                <div className='ikon-code-wrapper'>
                    <p>Your IKON Code:</p>
                    <p className='ikon-code'> {user.details.ikon} </p>
                </div>
            )}
        </div>
    );
};

export default IKONPanel;
