import { Timestamp } from 'firebase/firestore';

export const merch = [
    {
        name: 'Snowchella Hoodie',
        limited: true,
        live: false,
        price: '$49',
        link: 'https://www.freshprints.com/group-order/product?groupOrderId=100401&designGroupId=30520&styleCode=PRM4500',
        variants: [
            {
                color: '#c4c1c0',
                images: ['snowchella-front.png', 'snowchella-back.png'],
            },
        ],
    },
    {
        name: 'Die Hoodie',
        live: true,
        price: '$35',
        link: 'https://www.freshprints.com/group-order/product?groupOrderId=100275&designGroupId=30437&styleCode=G185',
        variants: [
            {
                color: '#d4d4d4',
                images: ['hoodie-grey-front.png', 'hoodie-grey-back.png'],
            },
            {
                color: '#000000',
                images: ['hoodie-black-front.png', 'hoodie-black-back.png'],
            },
        ],
    },
    {
        name: 'Die Sweats',
        live: true,
        price: '$30',
        link: 'https://www.freshprints.com/group-order/product?groupOrderId=100275&designGroupId=30438&styleCode=G182',
        variants: [
            {
                color: '#d4d4d4',
                images: ['sweats-grey-front.png', 'sweats-grey-back.png'],
            },
            {
                color: '#000000',
                images: ['sweats-black-front.png', 'sweats-black-back.png'],
            },
        ],
    },
    {
        name: 'Baby Tee',
        live: true,
        price: '$25',
        link: 'https://www.freshprints.com/group-order/product?groupOrderId=100275&designGroupId=30439&styleCode=43035',
        variants: [
            {
                color: '#fca4f4',
                images: ['baby-pink-front.png'],
            },
            {
                color: '#0057e3',
                images: ['baby-blue-front.png'],
            },
        ],
    },
    {
        name: 'Trucker Hat',
        live: true,
        price: '$35',
        link: 'https://www.freshprints.com/group-order/product?groupOrderId=100275&designGroupId=30440&styleCode=39-165',
        variants: [
            {
                color: '#0057e3',
                images: ['trucker-blue-front.png', 'trucker-blue-back.png'],
            },
        ],
    },
];

export const discounts = [
    {
        sponsor: 'Diamant',
        text: '30% off',
        code: 'UCLA',
        link: 'https://www.diamantskiing.com/',
    },
    {
        sponsor: 'Glory Poles',
        text: '20% off',
        code: 'BRUINS20',
        link: 'https://skiglory.com/',
    },
    {
        sponsor: 'Arbor Snow',
        text: '35% off',
        code: 'UCLA.BOARD.CLUB.35',
        link: 'https://www.arborcollective.com/pages/snowboards',
    },
    {
        sponsor: 'Nixon',
        text: '30% off',
        code: 'UCLASNOW2024',
        link: 'https://www.nixon.com/',
    },
    {
        sponsor: 'Tipsy Elves',
        text: '20% off',
        code: 'LASKI20',
        link: 'https://www.tipsyelves.com/',
    },
    {
        sponsor: 'Kemper Boards',
        text: '40% off',
        code: 'LASSC2240',
        link: 'https://kemper-snowboards.com/',
    },
    {
        sponsor: 'Snowfeet',
        text: '20% off',
        code: 'SNOWFEETCLUB',
        link: 'https://www.snowfeetstore.com/',
    },
    {
        sponsor: 'Bern Helmets',
        text: '20% off',
        code: 'LABC',
        link: 'https://www.bernhelmets.com/',
    },
    {
        sponsor: 'XEVO Optics',
        text: '50% off',
        code: 'ALLCAL',
        link: 'https://www.xevooptics.com/',
    },
    {
        sponsor: 'Virtika',
        text: '40% off',
        code: 'UCLA40',
        link: 'https://www.virtika.com/',
    },
    {
        sponsor: 'Coalition Snow',
        text: '15% off',
        code: '8C479D7FFCC',
        link: 'https://www.coalitionsnow.com/',
    },
    {
        sponsor: 'Banana Blades',
        text: '35% off',
        code: 'BananaUCLA23',
        link: 'https://bananablades.com/',
    },
    {
        sponsor: 'Powder LA',
        text: '20% off',
        code: 'UCLA20',
        link: 'https://powder-la.com/',
    },
];

export const products = {
    '23-24-membership': {
        prod_id: 'prod_NgzK2R0uPLOLKT',
        price_id: 'price_1MvbM4A8yISC5yksZ9KbJ2lp',
    },
    '24-25-membership': {
        prod_id: 'prod_PO8DETk33FIHYL',
        price_id: 'price_1P3R7wA8yISC5yksSM5p12LW',
    },
};

export const testEvents = [
    {
        title: 'MLK Mammoth Trip',
        desc_short:
            'Fill this out if you are NOT going on the bus to the rose bowl but want access to booze and food!',
        desc_long:
            'This is the form to sign up for the tailgate this Saturday (October 7th) for the UCLA vs. Washington State football game. For $30, you will receive bus transportation to and from the game, alcohol, and food. Departure will be at 8:30 AM on Gayley and the busses will leave the Rose Bowl approximately 20 minutes after the game ends. Space is limited!! You will be held responsible for the security deposit if you make any unnecessary mess on the bus (vomit/spilled drinks/broken glass). Trash bags will be supplied so please aim! Pregame @ 10991 Roebling Ave BACKYARD @ 7:30 AM. If you do not come to the pregame, make sure you are at the bus pickup location BEFORE 8:30 AM. We will leave without you.',
        prod_id: 'prod_POUz1Sc7jzLJ4e',
        general: {
            price_id: 'price_1OZhzPA8yISC5yksCyE5tBJd',
            max_qty: 50,
            cur_qty: 0,
        },
        drivers: {
            price_id: 'price_1OZhzPA8yISC5yksCyE5tBJd',
            max_qty: 5,
            cur_qty: 0,
        },
        live: true,
        open: new Timestamp(1697007600, 0),
        date: new Timestamp(1699153200, 0),
        custom_questions: [
            { type: 'text', title: 'What time can you leave on Friday?' },
            {
                type: 'radio',
                title: 'How many do you do?',
                options: ['One', 'Two'],
            },
        ],
        trip_doc_url:
            'https://docs.google.com/document/d/1BL8sigIUD0HzkD8xYtlvEaa73YH_2pZTpHghDsMi4bY/edit',
    },
];
