import { AppState } from '../context/AppContext';
import {
    convertFromFBTimestamp,
    convertToFBTimestamp,
    updateEvent,
    createEvent,
} from '../script/fbAPI';

import { useState, useEffect } from 'react';

import CustomQuestionsPanel from './CustomQuestionsPanel';

const EditEventModal = () => {
    const { event, setEvent, setSyncing } = AppState();

    // Conditional returns
    if (!event.data) return;
    if (!(event.type === 'edit')) return;

    const [confirmEnabled, setConfirmEnabled] = useState(false);

    // Event details
    const [live, setLive] = useState(false);
    const [type, setType] = useState('party');
    const [title, setTitle] = useState('');
    const [descShort, setDescShort] = useState('');
    const [descLong, setDescLong] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [openDate, setOpenDate] = useState('');
    const [openTime, setOpenTime] = useState('');
    const [tripDocUrl, setTripDocUrl] = useState('');

    // Custom questions
    const [customQuestions, setCustomQuestions] = useState([]);

    // Stripe details
    const [prodID, setProdID] = useState('');
    const [generalPriceID, setGeneralPriceID] = useState('');
    const [generalMaxQty, setGeneralMaxQty] = useState(0);
    const [staffPriceID, setStaffPriceID] = useState('');
    const [driversMaxQty, setDriversMaxQty] = useState(0);

    // Extra CSS used for validation
    const [extra, setExtra] = useState({
        date: {
            date: '',
            time: '',
        },
        open: {
            date: '',
            time: '',
        },
    });

    // Configure event properties at first render
    useEffect(() => {
        if (!event.data) return;

        setLive(event.data.live ? true : false);
        setTitle(event.data.title);
        setDescShort(event.data.desc_short);
        setDescLong(event.data.desc_long);
        setTripDocUrl(event.data.trip_doc_url ? event.data.trip_doc_url : '');
        setType(event.data.type)

        if (event.data.custom_questions)
            setCustomQuestions(event.data.custom_questions);

        setProdID(event.data.prod_id);

        if (event.data.general) {
            setGeneralPriceID(event.data.general.price_id);
            setGeneralMaxQty(event.data.general.max_qty);
        }
        if (event.data.drivers) {
            setDriversMaxQty(event.data.drivers.max_qty);
        }
        if (event.data.staff) {
            setStaffPriceID(event.data.staff.price_id);
        }

        // Date times require special handling
        if (event.data.date) {
            const temp = convertFromFBTimestamp(event.data.date);
            setDate(temp.date);
            setTime(temp.time);
        } else {
            setDate('');
            setTime('');
        }
        if (event.data.open) {
            const temp = convertFromFBTimestamp(event.data.open);
            setOpenDate(temp.date);
            setOpenTime(temp.time);
        } else {
            setOpenDate('');
            setOpenTime('');
        }
    }, [event]);

    const formIsValid = () => {
        return (
            title &&
            descShort &&
            descLong &&
            prodID &&
            date &&
            time &&
            openDate &&
            openTime &&
            prodID &&
            generalPriceID &&
            generalMaxQty
        );
    };

    useEffect(() => {
        const formValid = formIsValid();
        const enabled = formValid;
        setConfirmEnabled(enabled);
    });

    const handleCancelClick = () => {
        setEvent({});
    };

    const handleConfirmClick = async () => {
        // TODO: Visual form validation
        if (!formIsValid()) return;

        let valid = true;
        if (!isValidDate(date)) {
            let temp = { ...extra };
            temp.date.date = 'red-border';
            setExtra(temp);
            valid = false;
        }
        if (!isValidTime(time)) {
            let temp = { ...extra };
            temp.date.time = 'red-border';
            setExtra(temp);
            valid = false;
        }
        if (!isValidDate(openDate)) {
            let temp = { ...extra };
            temp.open.date = 'red-border';
            setExtra(temp);
            valid = false;
        }
        if (!isValidTime(openTime)) {
            let temp = { ...extra };
            temp.open.time = 'red-border';
            setExtra(temp);
            valid = false;
        }
        if (!valid) return;

        const updatedEvent = {
            live: live,
            type: type,
            title: title,
            desc_short: descShort,
            desc_long: descLong,
            prod_id: prodID,
            date: convertToFBTimestamp(date, time),
            open: convertToFBTimestamp(openDate, openTime),
            trip_doc_url: tripDocUrl,
            custom_questions: customQuestions,
            prod_id: prodID,
            general: {
                price_id: generalPriceID,
                max_qty: parseInt(generalMaxQty),
            },
            staff: {
                price_id: staffPriceID,
            },
            drivers: {
                max_qty: parseInt(driversMaxQty),
            },
        };

        setSyncing(true);
        if (event.data.uid) await updateEvent(event.data.uid, updatedEvent);
        else await createEvent(updatedEvent);
        setSyncing(false);
        setEvent({});
        if (event.callback) event.callback();
    };

    useEffect(() => {
        setExtra({
            date: {
                date: '',
                time: '',
            },
            open: {
                date: '',
                time: '',
            },
        });
    }, [date, time, openDate, openTime]);

    function formatDate(value) {
        if (!value) return value;
        const date = value.replace(/[^\d]/g, '');
        const dateLength = date.length;
        if (dateLength < 3) return date;
        if (dateLength < 5) {
            return `${date.slice(0, 2)}-${date.slice(2)}`;
        }
        return `${date.slice(0, 2)}-${date.slice(2, 4)}-${date.slice(4, 8)}`;
    }

    function formatTime(value) {
        if (!value) return value;
        const date = value.replace(/[^\d]/g, '');
        const dateLength = date.length;
        if (dateLength < 3) return date;
        return `${date.slice(0, 2)}:${date.slice(2, 4)}`;
    }

    // Validates month, day, year sections of date string
    function isValidDate(date) {
        if (date === '') return true;
        const m = parseInt(date.slice(0, 2));
        const d = parseInt(date.slice(3, 5));
        const y = parseInt(date.slice(6, 10));
        const valid =
            m > 0 && m <= 12 && d > 0 && d <= 31 && y > 2022 && y <= 2050;
        return valid;
    }

    // Validates time
    function isValidTime(time) {
        if (time === '') return true;
        const h = parseInt(time.slice(0, 2));
        const m = parseInt(time.slice(3, 5));

        const valid = h >= 0 && h < 24 && m >= 0 && m < 60;
        return valid;
    }

    return (
        <div className='modal-wrapper'>
            <div className='modal'>
                <div className='center-wrapper'>
                    <div className='event-edit-container'>
                        {/* Event details */}
                        <div className='input-column-wrapper'>
                            <div className='input-column'>
                                <h3>Event details</h3>
                                {/* Event live */}
                                <div className='checkbox-inline-wrapper margin-bottom'>
                                    <p>Make event live?</p>
                                    <div className='margin-left-small'>
                                        <input
                                            type='checkbox'
                                            onChange={(e) =>
                                                setLive(e.target.checked)
                                            }
                                            checked={live}
                                        />
                                    </div>
                                </div>
                                {/* Event type */}
                                <div className='margin-bottom'>
                                    <p>Event type</p>
                                    <div className='margin-left-small'>
                                        <label>
                                            <input
                                            type="radio"
                                            value="Tailgate"
                                            checked={type === 'tailgate'}
                                            onChange={() => setType('tailgate')}
                                            />
                                            Tailgate
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                            type="radio"
                                            value="Party"
                                            checked={type === 'party'}
                                            onChange={() => setType('party')}
                                            />
                                            Party
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                            type="radio"
                                            value="Cabin Trip"
                                            checked={type === 'cabinTrip'}
                                            onChange={() => setType('cabinTrip')}
                                            />
                                            Cabin Trip
                                        </label>
                                        <br />
                                        <label>
                                            <input
                                            type="radio"
                                            value="Externally Linked"
                                            checked={type === 'external'}
                                            onChange={() => setType('external')}
                                            />
                                            Externally Linked
                                        </label>
                                    </div>
                                </div>
                                {/* Title */}
                                <p>Title</p>
                                <div className='text-input-wrapper padded border'>
                                    <input
                                        className='text-input'
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                        value={title}
                                        placeholder=''
                                    />
                                </div>
                                {/* Short description */}
                                <p>Short description (1 sentence)</p>
                                <div className='text-input-wrapper padded border'>
                                    <textarea
                                        className='text-input small'
                                        onChange={(e) =>
                                            setDescShort(e.target.value)
                                        }
                                        value={descShort}
                                        placeholder=''
                                    />
                                </div>
                                {/* Long description */}
                                <p>Long description</p>
                                <div className='text-input-wrapper padded border'>
                                    <textarea
                                        className='text-input large'
                                        onChange={(e) =>
                                            setDescLong(e.target.value)
                                        }
                                        value={descLong}
                                        placeholder=''
                                    />
                                </div>
                                {/* Event date-time */}
                                <div className='datetime-inline-wrapper'>
                                    <div className='full-width'>
                                        <p>Event date</p>
                                        <div className='text-input-wrapper padded border'>
                                            <input
                                                className={`text-input ${extra.date.date}`}
                                                onChange={(e) =>
                                                    setDate(
                                                        formatDate(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                                value={date}
                                                placeholder='mm-dd-yyyy'
                                            />
                                        </div>
                                    </div>
                                    <div className='margin-left-small'>
                                        <p>Time</p>
                                        <div className='text-input-wrapper padded border time'>
                                            <input
                                                className={`text-input ${extra.date.time}`}
                                                onChange={(e) =>
                                                    setTime(
                                                        formatTime(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                                value={time}
                                                placeholder='hh:mm (24h)'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Open date-time */}
                                <div className='datetime-inline-wrapper'>
                                    <div className='full-width'>
                                        <p>Open date</p>
                                        <div className='text-input-wrapper padded border'>
                                            <input
                                                className={`text-input ${extra.open.date}`}
                                                onChange={(e) =>
                                                    setOpenDate(
                                                        formatDate(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                                value={openDate}
                                                placeholder='mm-dd-yyyy'
                                            />
                                        </div>
                                    </div>
                                    <div className='margin-left-small'>
                                        <p>Time</p>
                                        <div className='text-input-wrapper padded border time'>
                                            <input
                                                className={`text-input ${extra.open.time}`}
                                                onChange={(e) =>
                                                    setOpenTime(
                                                        formatTime(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                                value={openTime}
                                                placeholder='hh:mm (24h)'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Trip Doc URL */}
                                { (type === 'cabinTrip' || type === 'external') && (
                                    <div>
                                        <p>Trip document URL</p>
                                        <div className='text-input-wrapper padded border'>
                                            <input
                                                className='text-input'
                                                onChange={(e) =>
                                                    setTripDocUrl(e.target.value)
                                                }
                                                value={tripDocUrl}
                                                placeholder=''
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='input-column'>
                                <CustomQuestionsPanel
                                    questions={customQuestions}
                                    setQuestions={setCustomQuestions}
                                />
                            </div>
                            {type != 'external' && (
                                <div className='input-column'>
                                    <h3>Stripe details</h3>
                                    {/* Stripe Product ID */}
                                    <p>Product ID</p>
                                    <div className='text-input-wrapper padded border'>
                                        <input
                                            className='text-input'
                                            autoComplete='off'
                                            onChange={(e) =>
                                                setProdID(e.target.value)
                                            }
                                            value={prodID}
                                            placeholder=''
                                        />
                                    </div>
                                    {/* General section */}
                                    { type === 'tailgate' && (
                                        <div>
                                            <h4>Members</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                    <p>Price ID</p>
                                                    <div className='text-input-wrapper padded border'>
                                                        <input
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralPriceID(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalPriceID}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                                <div className='margin-left-small'>
                                                    <p>Max qty</p>
                                                    <div className='text-input-wrapper padded border time'>
                                                        <input
                                                            type='number'
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralMaxQty(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalMaxQty}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <h4>Staff</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                    <p>Price ID</p>
                                                    <div className='text-input-wrapper padded border'>
                                                        <input
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setStaffPriceID(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={staffPriceID}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    )}
                                    { type === 'cabinTrip' && (
                                        <div>
                                            <h4>Members</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                    <p>Price ID</p>
                                                    <div className='text-input-wrapper padded border'>
                                                        <input
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralPriceID(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalPriceID}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                                <div className='margin-left-small'>
                                                    <p>Max qty</p>
                                                    <div className='text-input-wrapper padded border time'>
                                                        <input
                                                            type='number'
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralMaxQty(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalMaxQty}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <h4>Drivers</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                        <p>Price ID</p>
                                                        <div className='text-input-wrapper padded border'>
                                                            <input
                                                                className='text-input'
                                                                onChange={(e) =>
                                                                    setGeneralPriceID(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                value={generalPriceID}
                                                                placeholder=''
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                <div className='margin-left-small'>
                                                    <p>Max qty</p>
                                                    <div className='text-input-wrapper padded border time'>
                                                        <input
                                                            type='number'
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setDriversMaxQty(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={driversMaxQty}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <h4>Staff</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                    <p>Price ID</p>
                                                    <div className='text-input-wrapper padded border'>
                                                        <input
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setStaffPriceID(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={staffPriceID}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    )}
                                    { type === 'party' && (
                                        <div>
                                            <h4>Non-members</h4>
                                            <div className='datetime-inline-wrapper'>
                                                <div className='full-width'>
                                                    <p>Price ID</p>
                                                    <div className='text-input-wrapper padded border'>
                                                        <input
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralPriceID(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalPriceID}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                                <div className='margin-left-small'>
                                                    <p>Max qty</p>
                                                    <div className='text-input-wrapper padded border time'>
                                                        <input
                                                            type='number'
                                                            className='text-input'
                                                            onChange={(e) =>
                                                                setGeneralMaxQty(
                                                                    e.target.value
                                                                )
                                                            }
                                                            value={generalMaxQty}
                                                            placeholder=''
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* Action buttons */}
                        <div className='confirm-button-grid'>
                            <button
                                className='secondary'
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                            <button
                                className={`primary ${
                                    confirmEnabled ? '' : 'disabled'
                                }`}
                                onClick={handleConfirmClick}
                                disabled={!confirmEnabled}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEventModal;
