import mountains from '../assets/images/background.jpeg';
import trees from '../assets/images/background-2.jpg';
import pumpkins from '../assets/images/pumpkins.jpg';
import snowyMountains from '../assets/images/mountains2.jpg';


const Banner = () => {
    return (
        <div className='bg-image-wrapper'>
            <div className='tinter' />
            <img className='bg-image'
                    src={snowyMountains} alt='Snowy Mountains' />
            {/* <img className='bg-image'
                    src={pumpkins} alt='Pumpkins' /> */}
        </div>
    )
}

export default Banner;