import {
    getEvents,
    userHasPaidForEvent,
    setUserHasPaidForEvent,
    getUserInfoFromUID,
    getAge,
} from '../script/fbAPI';
import { useState, useEffect } from 'react';

import EventsCollection from './EventsCollection';
import DataPlaceholder from './DataPlaceholder';

import { AppState } from '../context/AppContext';
import { UserAuth } from '../context/AuthContext';

const EventsPanel = ({override = false}) => {
    const [events, setEvents] = useState([]);
    const { verified } = AppState();
    const { user } = UserAuth();

    useEffect(() => {
        handleGetEvents();
    }, [user]);

    const handleGetEvents = async () => {
        let data = await getEvents(user);

        // Check if user has submitted form response to event and they haven't paid
        if (user) {
            data = await Promise.all(
                data.map(async (event) => {
                    let responses = event.responses;

                    if (responses) {
                        let userResponse = responses[user.uid];

                        if (userResponse && !userResponse.paid) {
                            let hasPaid = await userHasPaidForEvent(
                                user,
                                event
                            );

                            if (hasPaid) {
                                // Update the local copy of the event
                                const updatedEvent = {
                                    ...event,
                                    responses: {
                                        ...responses,
                                        [user.uid]: {
                                            ...userResponse,
                                            paid: true,
                                        },
                                    },
                                };

                                // Make call to server to update the database
                                setUserHasPaidForEvent(user, event);

                                return updatedEvent;
                            }
                        }
                    }
                    return event;
                })
            );
        }
        
        setEvents(data);
    };

    if (events.length == 0) return null;

    return (
        <div className='info-wrapper'>
            <h2>Events</h2>
            {(verified || override) && <EventsCollection events={events} editable={false} />}
            {!(verified || override) && <DataPlaceholder locked={true} />}
        </div>
    );
};

export default EventsPanel;
