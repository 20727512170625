import { UserAuth } from '../context/AuthContext';
import { AgGridReact } from 'ag-grid-react';
import { useState, useEffect, useRef } from 'react';

import { getMemberData, updateUserIkonCodesFromCSV} from '../script/fbAPI';

import CodeInput from './CodeInput';
import ScheduleLink from './ScheduleLink';
import MemberAge from './MemberAge';
import MemberName from './MemberName';
import RemoveMember from './RemoveMember';
import TeeCheckbox from './TeeCheckbox';
import EarlyAccessCheckbox from './EarlyAccessCheckbox';
import PermissionsMenu from './PermissionsMenu';

import { AppState } from '../context/AppContext';

import EditEventsPanel from './EditEventsPanel';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// TODO: Move all this junk to another AgGrid
// TODO: Only global modal should by sync modal. Rest can be included locally with callbacks (we can create a single confirm modal with custom confirm text)

const AdminContents = () => {
    const { user } = UserAuth();
    const { setSyncing } = AppState();
    const [filterText, setFilterText] = useState('');
    const [memberCount, setMemberCount] = useState(0);

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const staffDef = [
        {
            field: 'name',
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        { field: 'age', cellRenderer: MemberAge, width: 70 },
        { field: 'phone', suppressMovable: true },
        { field: 'emergency', suppressMovable: true },
        { field: 'tee', cellRenderer: TeeCheckbox, width: 70 },
    ];
    const execDef = [
        {
            field: 'name',
            sortable: true,
            resizable: true,
            cellRenderer: MemberName,
            suppressMovable: true,
            comparator: (valueA, valueB, nodeA, nodeB) => {
                // Determine if each row should be highlighted
                const isHighlightedA = nodeA.data.phone && nodeA.data.emergency && nodeA.data.schedule && !nodeA.data.ikon;
                const isHighlightedB = nodeB.data.phone && nodeB.data.emergency && nodeB.data.schedule && !nodeB.data.ikon;
        
                // Sort highlighted rows to the top
                if (isHighlightedA && !isHighlightedB) {
                    return -1;
                } else if (!isHighlightedA && isHighlightedB) {
                    return 1;
                }
        
                // If both rows have the same highlight status, optionally sort by name or other criteria
                return valueA.localeCompare(valueB);
            },
        },
        { field: 'uid', resizable: true, suppressMovable: true },
        {
            field: 'email',
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        { field: 'dob', suppressMovable: true },
        { field: 'phone', suppressMovable: true },
        { field: 'emergency', suppressMovable: true },
        {
            field: 'schedule',
            cellRenderer: ScheduleLink,
            width: 100,
            suppressMovable: true,
        },
        { field: 'IKON', cellRenderer: CodeInput, suppressMovable: true },
        { field: 'tee', cellRenderer: TeeCheckbox, width: 60 },
        {
            field: 'permissions',
            cellRenderer: PermissionsMenu,
            width: 150,
        },
        {
            field: 'earlyFormAccess',
            cellRenderer: EarlyAccessCheckbox,
            width: 150,
        },
        {
            field: 'remove',
            cellRenderer: RemoveMember,
            width: 100,
            suppressMovable: true,
        },
    ];

    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!checked);
        localStorage.setItem('checked', !checked);
    };

    useEffect(() => {
        if (checked) {
            setColumnDefs(staffDef);
            if (gridRef.current && gridRef.current.api)
                gridRef.current.api.setColumnDefs(staffDef);
        } else {
            setColumnDefs(execDef);
            if (gridRef.current && gridRef.current.api)
                gridRef.current.api.setColumnDefs(execDef);
        }
        // Force refresh the grid
        if (gridRef.current && gridRef.current.api) {
            const allColumnIds = [];
            gridRef.current.columnApi.getColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            gridRef.current.columnApi.autoSizeColumns(allColumnIds);
        }
        gridRef.current.suppressCellSelection = true;
        // gridRef.current.api.sizeColumnsToFit();
    }, [checked]);

    useEffect(() => {
        // Set column definitions (staff or exec)
        if (user.details) {
            switch (user.details.permissions) {
                case 2:
                    setColumnDefs(staffDef);
                    break;
                case 3:
                    // Apply checked preference
                    const localChecked = localStorage.getItem('checked');
                    if (localChecked) {
                        const parsed = JSON.parse(localChecked);
                        if (parsed === true || parsed === false)
                            setChecked(parsed);
                    }
                    break;
            }
        }
        // Set row data
        const handleSetMemberData = async () => {
            setSyncing(true);
            const data = await getMemberData();
            setMemberCount(data.length);
            setRowData(data);
            setSyncing(false);
        };
        if (user) handleSetMemberData();
    }, [user]);

    // AgGrid Search feature
    useEffect(() => {
        if (gridRef.current.api) {
            gridRef.current.api.setQuickFilter(filterText);
        }
    }, [filterText]);

    // const handleUpdateUserIkonCodesFromCSV = () => {
    //     updateUserIkonCodesFromCSV();
    // }

    return (
        <div className='h-center'>
            <div className='content-wrapper'>
                {/* <button onClick={() => handleUpdateUserIkonCodesFromCSV()}>     
                handleUpdateUserIkonCodesFromCSV
                </button> */}
                <h1>Admin Panel</h1>
                <div className='info-wrapper'>
                    <div className='member-count-badge'>
                        <p className='member-count'>{ memberCount }</p>
                        <p>Members</p>
                    </div>
                    {user.details && user.details.permissions >= 3 && (
                        <div className='checkbox-wrapper'>
                            <input
                                type='checkbox'
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                            <p> Show simplified table</p>
                        </div>
                    )}
                    {/* Search */}
                    <div className='text-input-wrapper short border margin-bottom'>
                        <input
                            className='text-input'
                            type='text'
                            placeholder='Search...'
                            value={filterText}
                            onChange={(event) =>
                                setFilterText(event.target.value)
                            }
                        />
                    </div>
                    {/* AgGrid */}
                    <div
                        className='ag-theme-alpine'
                        style={{ width: 'auto', height: 500 }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                        ></AgGridReact>
                    </div>
                    {/* Edit Events */}
                    <EditEventsPanel />
                </div>
            </div>
        </div>
    );
};

export default AdminContents;
